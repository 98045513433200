<template>
    <laboratory-profile-component/>
</template>

<script>
    import LaboratoryProfileComponent from "@/components/laboratories/LaboratoryProfileComponent";
    
    export default {
        name: "LaboratoryProfile",
        title: "Perfil del Laboratorio | Turismo BC",
        components: { LaboratoryProfileComponent }
    }
</script>

<style scoped>

</style>